import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import config from "@/config";
import vClickOutside from "click-outside-vue3";

import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
require("dayjs/locale/fr");
dayjs.extend(relativeTime);
dayjs.locale("fr");

library.add(fas, fab, far);

const app = createApp(App);

app.use(Vue3Toasity, {
  autoClose: config.timeout,
  position: "top-right",
  dangerouslyHTMLString: true,
  newestOnTop: true,
  theme: "colored",
  toastClassName: "toast",
});

const plugins = [
  router,
  store,
  vClickOutside,
];
plugins.forEach((plugin) => app.use(plugin));

app.component("fa-icon", FontAwesomeIcon);

app.provide("axios", app.config.globalProperties.axios);

app.config.globalProperties.$filters = {
  timeAgo(date) {
    return dayjs(date).fromNow();
  },
  formatDate(date) {
    return dayjs(date).format("DD/MM/YYYY");
  },
}

app.mount("#app");
export default app;
