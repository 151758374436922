const pieces = [
  {
    key: "la_salle_de_bain",
    titre: "La Salle de bain",
    description: `
      <p>
        La troupe de théâtre "Les Tent'Actrices" voit le jour en avril 2007, à l'initiative de Marina.
        S'entourant de 4 femmes (Evelyne, Lorraine, Maria et Laurence), le choix de la
        première pièce se porte tout naturellement vers "La Salle de Bain" d'Astrid Veillon, comédie intimiste
        exclusivement féminine. Ayant peu ou pas d'expérience, les cinq comédiennes se lancent dans l'arène et le
        succès est au rendez-vous&nbsp;! Deux représentations sont données les 27 et 28 octobre 2007 au château des
        Rohan à MUTZIG, à guichets fermés. Encouragées par ce succès, elles jouent les prolongations avec deux séances
        supplémentaires en février 2008. Salle comble et filles comblées&nbsp;!
      </p>
      <p>
        Une cinquième représentation sera donnée à Rosheim où le succès sera à nouveau au rendez-vous.
        L'aventure n'est pas tout à fait terminée&nbsp;; la troupe a l'immense plaisir de revenir une dernière fois au
        Château des Rohan le 6 décembre 2008, et se fait une grande joie de reverser l'intégralité des recettes de
        billetterie pour le Téléthon.
      </p>
    `
  },
  {
    key: "les_amazones",
    titre: "Les Amazones",
    description: `
    <p>
        Après cette superbe expérience, il faut trouver une autre pièce comportant cinq rôles féminins, et c'est une
        évidence, il n'y en a pas ou presque pas sur le marché du théâtre&nbsp;! Deux solutions se présentent&nbsp;:
        soit accueillir des garçons mais les candidatures sont rarissimes, soit des comédiennes se sentent d'attaque
        pour interpréter des rôles masculins… Challenge difficile. Après de longues hésitations, Lorraine accepte de
        se mettre dans la peau d'un garçon et Catherine, qui vient de rejoindre la troupe, jouera le second jeune
        homme dans une pièce comique de Jean-Marie CHEVRET, "Les Amazones."
      </p>
    `
  },
  {
    key: "elle_voit_des_nains_partout",
    titre: "Elle voit des nains partout",
    description: `
      <p>
        Pour leur troisième pièce, changement de registre&nbsp;: la troupe arrête son choix sur "Elle voit des nains
        partout", une pièce complètement loufoque et déjantée de Philippe BRUNEAU. Marina adapte cette comédie
        burlesque à la personnalité de chaque comédienne, transformant le rôle de cuisinier italien en cuisinier
        alsacien, qui donnera un charme particulier à ce conte de fées revisité.
      </p>
    `
  },
  {
    key: "toc_toc",
    titre: "Toc Toc",
    description: `
      <p>
        Quelle magnifique aventure cette pièce, et quelle galère aussi&nbsp;! De tels fous rires pendant les
        répétitions et surtout dans la salle lors des représentations&nbsp;! Cette pièce est difficile, le rythme est
        d'enfer et demande un investissement énorme de la part des comédiens qui ne sortent jamais de la scène, zéro
        temps mort&nbsp;! Adrien assurera avec brio le seul rôle masculin, et Brigitte, venue nous
        prêter main forte, sera au top dans son rôle&nbsp;! Quel talent&nbsp;! Bravo et merci à tous les deux&nbsp;!
      </p>
      <p>
        Nous donnons six représentations de Toc Toc au château des Rohan en octobre 2013, toutes à guichets fermés.
        Nous avons dû refuser quelques centaines de personnes, c'est pourquoi nous faisons le choix, à l'unanimité, de
        la rejouer en mars 2014. La recette d'une des soirées sera remise à l'ARAME (enfants malades de l'hôpital de
        Hautepierre), une très belle aventure humaine. Nous sommes vraiment heureux d'avoir pu rejouer cette super
        pièce dans une salle pleine à craquer. Merci à tous, et aussi à Laurent BAFFIE&nbsp;!
      </p>
    `
  },
  {
    key: "si_c_etait_a_refaire",
    titre: "Si c'était à refaire",
    description: `
      <p>
        Pour cette nouvelle saison, la pièce retenue par le quintette de comédiennes est signée de la plume drôle et
        subtile de Laurent RUQUIER. Une comédie délirante, rythmée et hilarante. Adrien ne pourra nous rejoindre pour
        cette pièce, ses études lui prenant énormément de temps&nbsp;; évidemment le sexe fort est à nouveau aux
        abonnés absents pour cette pièce. Qu'à cela ne tienne, cette fois-ci, c'est Marina qui s'y colle. Elle
        endossera donc avec ses peu d'attributs masculins le rôle de ce chirurgien esthétique en vogue et quelque peu…
        spécial&nbsp;! Le public est à nouveau au rendez-vous pour les six représentations, plus aucun siège de libre
        dans la salle d'attente du Dr. JOUVENCE&nbsp;! Rires et fous rires fuseront tout au long des séances. Un pur
        bonheur, pour la troupe et pour les spectateurs.
      </p>
    `
  },
  {
    key: "le_gai_mariage",
    titre: "Le Gai Mariage",
    description: `
      <p>
        Pour la saison 2018-2019, le choix de notre nouvelle comédie s'arrête sur "Le Gai Mariage". Un vaudeville de
        Gérard BITTON et Michel MUNTZ, auteurs du film "La vérité si je mens". Quelle pièce&nbsp;! Quand les fous
        rires vous prennent en pleine répétition, c'est plutôt bon signe&nbsp;! Drôle à souhait, quiproquos et
        retournements de situation s'enchaînent à jet continu&nbsp;! Des rires, des rires, et encore des rires
        envahissent la salle et une véritable complicité s'installe entre le public et les comédiens, avec une Peggy
        la Cochonne (Marina) pas très convaincante, mais plutôt convaincue de son grand talent d'animatrice
        d'anniversaires pour enfants… De bon cœur et à l'unanimité, les spectateurs pousseront la chansonnette qu'elle
        leur proposera… Trop fort notre public&nbsp;! Et que dire d'Adrien, qui a su déclencher des fous rires
        interminables, une véritable prouesse de comédien&nbsp;! Et Maria, qui du haut de ses presque huit décennies a
        fait pleurer de rire toute la salle, en passant de la bigote super-coincée à la hard-rockeuse déjantée&nbsp;!
        Quant à Lorraine, son rôle de future épouse par intérêt, volage et libre comme l'air, était à la mesure de son
        talent&nbsp;! Formidable&nbsp;! Et alors Evelyne, une avocate tellement délurée qui atteindra le firmament des
        pétages de plombs avec grand art&nbsp;! Woaw&nbsp;! Et comme à l'accoutumée, nous jouerons les six
        représentations à guichets fermés&nbsp;! De superbes souvenirs et un immense bravo au comédien et aux
        comédiennes qui ont vraiment assuré&nbsp;!
      </p>
    `
  },
  {
    key: "un_beau_salaud",
    titre: "Un Beau Salaud",
    description: `
      <p>
        Un succès bien mérité (quatre représentations à guichets fermés) pour cette comédie écrite par Pierre CHESNOT.
        Un rythme endiablé et soutenu, un one-man show dans une pièce de théâtre, un texte maniant l'humour avec grand
        talent, que demander de plus&nbsp;? Adrien, au meilleur de sa forme théâtrale, assure ce seul-en-scène avec un
        talent inné&nbsp;! Il se glisse avec une aisance sans pareille dans la peau de François, le beau salaud, et le
        public est conquis dès les premières tirades. Et c'est justement ce soir que ce manipulateur a décidé
        d'annoncer à sa femme qu'il la quittait pour une jeunette de 20 ans… Mais c'est mal connaître Catherine, son
        épouse, dont Marina se glisse à merveille dans la peau de cette bourgeoise trompée. Elle peut toujours compter
        sur son amie Mado, première femme de François, toujours prête pour de bons conseils. Ce rôle, joué par
        l'excellente Maria, apportera une couleur particulièrement drôle à la pièce, et déclenchera des rires à chaque
        réplique&nbsp;! Quel succès&nbsp;! Et voilà que déboule Barbara, la maîtresse complètement excitée et
        déprimée. "Votre mari me trompe&nbsp;!", annonce-t-elle à Catherine. Dès son entrée en scène, le public se
        gondole&nbsp;! Interprété avec maestria par Evelyne, son rôle très physique lui vaudra quelques hématomes
        provoqués par ses nombreuses chutes&nbsp;! Et que dire du formidable jeu de Lorraine dans le rôle d'Adeline,
        une psychiatre très habitée, qui lorgne un peu trop sur la bouteille de Martini… et sur François&nbsp;! Elle
        va provoquer des fous rires non seulement dans la salle, mais aussi sur la scène&nbsp;! Un exploit&nbsp;! Une
        ligue féministe va se former entre toutes ses femmes quand entre Léa, l'amoureuse tellement jolie et sexy en
        diable, prête à s'envoler avec son prince charmant pour des horizons exotiques. C'est Claudia, foulant pour la
        première fois les planches, qui enfilera la magnifique robe rouge moulante de cette jeune nymphette,
        magnifique également de naturel et d'insolence du haut de ses 20 ans&nbsp;! Une très belle interprétation de
        sa part, pour qui l'avenir théâtral est tout tracé.
      <p>
        Mais n'oublions pas nos souffleuses, Marceline et Josiane, ainsi que nos techniciens Sylvain et Pascal, et
        et bien sûr nos bénévoles. Nous leur devons une grande part de notre succès&nbsp;! Merci&nbsp;!
      </p>
      <p>Rendez-vous dans 2 ans pour une nouvelle aventure&nbsp;!</p>
    `
  },
];

export default pieces;
