import axios from "axios";
import config from "@/config";
import store from "@/store";

window.debugRequests = {}

export default (objConfigParam) => {
  const objConfig = objConfigParam || {};
  let headers = {};

  // On doit préciser le noHeader quand on appelle la route sans être connecté : Api({ noHeader: ... })
  if (!objConfig.noHeader) {
    headers = { Authorization: `Token ${store.getters.token}` };
  }

  const instance = axios.create({
    baseURL: config.api,
    timeout: objConfig.timeout || 10000,
    headers,
    queue: objConfig.queue,
  });

  return instance;
};
