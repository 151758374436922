<template>
  <ModalBase
    class="modal-auth"
    name="forgot_password"
    title="Mot de passe oublié"
    @click-outside="() => closeAndClear()"
  >
    <template #topbar>
      <button
        class="bold"
        type="button"
        @click="openLoginModal"
      >
        <fa-icon icon="fa-solid fa-arrow-right" />
        Connexion
      </button>
    </template>

    <template #body>
      <p>Nous vous enverrons un lien de réinitialisation de votre mot de passe à cette adresse.</p>
      <form @submit.prevent="sendMail">
        <InputForm
          v-model="mailRecover"
          type="mail"
          label="Votre adresse mail"
        />
        <p class="error-message">
          {{ errorMessage }}
        </p>
        <div class="zone-btn">
          <button
            class="btn"
            type="submit"
            :disabled="sendMailDisabled.value"
          >
            Envoyer
            <fa-icon icon="fa-solid fa-envelope" />
          </button>
        </div>
      </form>
    </template>
  </ModalBase>
</template>

<script setup>
import { ref } from "vue";
import { toast } from "vue3-toastify";

import { isValidEmail } from "@/utils";
import config from "@/config";

import Api from "@/modules/axios";
import { openModal, closeModal } from "@/modules/modale";

import InputForm from "@/components/InputForm";
import ModalBase from "@/components/modales/ModalBase.vue";

let sendMailDisabled = ref(false);
let mailRecover = ref("");
let errorMessage = ref("");

/**
 * Ouvre la modale de connexion.
 */
const openLoginModal = () => {
  closeAndClear();
  openModal("login");
};

/**
 * Ferme la modale et vide tous les champs.
 */
 const closeAndClear = () => {
  closeModal("forgot_password");

  sendMailDisabled = false;
  mailRecover.value = "";
  errorMessage.value = "";
};

/**
 * Envoie un email de réinitialisation du mot de passe.
 */
const sendMail = () => {
  if (!mailRecover.value) {
    return errorMessage.value = "Veuillez renseigner votre adresse email.";
  }

  if (!isValidEmail(mailRecover.value)) {
    return errorMessage.value = "L'email est invalide.";
  }

  sendMailDisabled = true;

  Api({ noHeader: true })
    .post(`${config.api}/password_reset/`, { email: mailRecover.value })
    .then((res) => {
      if (res.data.status === "OK") {
        closeModal("forgot_password");
        toast.success("Un email de modification de mot de passe vous a été envoyé.");
      } else if (res.data?.email[0]?.indexOf("no active user associated") > 0) {
        toast.error("Aucun compte n'est lié à cette adresse email.");
      } else {
        toast.error(res.data?.email[0]);
      }
    })
    .catch((err) => {
      if (err.response?.status === 400) {
        errorMessage.value = "Cette adresse email n'est pas enregistrée sur le site.";
      } else {
        errorMessage.value = err.response?.data?.error || "Une erreur est survenue.";
      }
    })
    .finally(() => sendMailDisabled = false);
};
</script>

<style lang="scss">
@use "@/assets/styles/components/_modal_auth.scss";
</style>
