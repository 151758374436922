<template>
  <ModalBase
    class="modal-auth"
    name="register"
    title="Inscription"
  >
    <template #topbar>
      <button
        class="bold"
        type="button"
        @click="openLoginModal"
      >
        <fa-icon icon="fa-solid fa-arrow-right" />
        Connexion
      </button>
    </template>

    <template #body>
      <form @submit.prevent="register">
        <InputForm
          v-model="registerInfo.first_name"
          label="Votre prénom"
          placeholder="Marina"
        />
        <InputForm
          v-model="registerInfo.last_name"
          class="nom"
          label="Votre nom"
          placeholder="BEYER"
        />
        <InputForm
          v-model="registerInfo.email"
          type="mail"
          label="Votre adresse email"
        />
        <InputForm
          v-model="registerInfo.password"
          type="password"
          label="Votre mot de passe"
          placeholder="Chivita_Chivita_HaHaHa!"
        />
        <InputForm
          v-model="registerInfo.password_repeat"
          type="password"
          label="Répétez votre mot de passe"
          placeholder="Chivita_Chivita_HaHaHa!"
        />
        <p class="error-message">
          {{ errorMessage }}
        </p>
        <div class="zone-btn">
          <button
            class="btn"
            type="submit"
          >
            S'inscrire
            <fa-icon icon="fa-solid fa-right-to-bracket" />
          </button>
        </div>
      </form>
    </template>
  </ModalBase>
</template>

<script setup>
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

import config from "@/config";

import Api from "@/modules/axios";
import { closeModal, openModal } from "@/modules/modale";

import InputForm from "@/components/InputForm";
import ModalBase from "@/components/modales/ModalBase.vue";

const store = useStore();

let errorMessage = ref("");

const registerInfo = reactive({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_repeat: "",
});
let loginInfos = reactive({
  username: "",
  password: "",
});

/**
 * Ouvre la modale de connexion.
 */
const openLoginModal = () => {
  closeAndClear();
  openModal("login");
};

/**
 * Ferme la modale et vide tous les champs.
 */
 const closeAndClear = () => {
  closeModal("register");

  errorMessage.value = "";

  Object.keys(registerInfo).forEach(key => {
    registerInfo[key] = "";
  });

  Object.keys(loginInfos).forEach(key => {
    loginInfos[key] = "";
  });
};

/**
 * Enregistre l'utilisateur.
 */
const register = () => {
  const infos = {
    ...registerInfo,
    last_name: registerInfo.last_name.toUpperCase(),
    username: registerInfo.email,
  };

  Api({ noHeader: true })
    .post(`${config.api}/signup/`, infos)
    .then(() => {
      loginInfos = {
        username: registerInfo.email,
        password: registerInfo.password,
      };
      login();
      toast.success("Votre compte a bien été créé&nbsp;!");
    })
    .catch((err) => {
      errorMessage.value = err.response?.data?.error  || "Une erreur est survenue.";
    });
};

/**
 * Connecte l'utilisateur.
 */
 const login = () => {
  store.dispatch("login", loginInfos)
    .then(() => {
      closeAndClear();
      toast.success("Connexion réussie&nbsp;!");
    })
    .catch((err) => {
      errorMessage.value = err.response?.data?.error  || "Une erreur est survenue.";
    });
};
</script>

<style lang="scss">
@use "@/assets/styles/components/_modal_auth.scss";
</style>
