export default [
  {
    key: "pascal",
    prenom: "Pascal",
    nom: "DURRENBERGER",
    genre: "H",
    intro: `
      Perché dans sa régie aux commandes des lumières, il a la meilleure place pour admirer les décors. Si vous
      entendez un gros rire provenant du plafond, aucun doute, c'est bien lui&nbsp;!
    `,
    aime: "Les gens qui arrivent à l'heure",
    deteste: "Les spectateurs qui utilisent leurs téléphones pendant la pièce (il voit tout)",
  },
  {
    key: "adrien",
    prenom: "Adrien",
    nom: "GUENAULT",
    genre: "H",
    intro: `
      Chargé de la communication sur les réseaux sociaux et webmaster, il est toujours connecté pour échanger avec
      vous. Il s'occupe également du son et a endossé le rôle de comédien par trois fois.
    `,
    aime: "Les fous rires sur scène",
    deteste: "Les comédiennes en panique qui le stressent avant chaque représentation",
  },
  {
    key: "marceline",
    prenom: "Marceline",
    nom: "RIEHL",
    genre: "F",
    intro: `
      Cachée derrière les pendrillons, elle surveille les entrées/sorties des comédiens et est également notre
      seconde souffleuse. Mais son plaisir, c'est de s'occuper des bruitages et de la voix off&nbsp;!
    `,
    aime: "Faire de brèves apparitions sur scène",
    deteste: "Devoir attendre le DVD pour voir à quoi ressemblait la pièce",
  },
  {
    key: "unknown",
    prenom: "Sabine",
    nom: "KAUFFER",
    genre: "F",
    intro: `
      Fraîchement arrivée dans la troupe en tant que souffleuse, elle est au premier rang pour aider les comédiennes
      lorsqu'elles <del>ont oublié leur texte</del> miment un silence un peu trop long&nbsp;!
    `,
    aime: "Lire et bavarder avec tout le monde",
    deteste: "Vous le saurez très prochainement…",
  },
  {
    key: "josiane",
    prenom: "Josiane",
    nom: "PLANQUE",
    genre: "F",
    intro: `
      Après 15 ans dans son rôle de souffleuse, elle change sa vocation pour se consacrer à aider les spectateurs à
      trouver une place et à les servir à la cafétéria après les représentations&nbsp;!
    `,
    aime: "Raconter qu'elle était la nounou d'Adrien quand il était petit",
    deteste: "Devoir remplacer un comédien absent aux répétitions",
  },
  {
    key: "sylvain",
    prenom: "Sylvain",
    nom: "KOLB",
    genre: "H",
    intro: `
      Technicien son en stand-by pour quelques temps, il a choisi de se consacrer un peu plus à sa vie personnelle,
      là où on ne lui dira pas que le son était trop fort ou trop faible&nbsp;!
    `,
    aime: "Une sono qui marche sans problème",
    deteste: "Les spectateurs qui essayent de passer derrière sa régie",
  },
];

// Sylvain:
// Il est le technicien son de la troupe. Si le son est trop faible ou trop fort dans la salle, c'est la faute du spectateur qui a une mauvaise oreille&nbsp;!
