<template>
  <label
    class="input-form"
    :class="{ disabled }"
  >
    {{ props.label }}&nbsp;:
    <textarea
      v-if="props.type === 'textarea'"
      :value="props.modelValue"
      :placeholder="props.placeholder"
      :disabled="disabled"
      @input="updateValue"
    />
    <input
      v-else
      :class="{ toggle: props.type === 'password' }"
      :value="props.modelValue"
      :type="props.type === 'password' && data.showPassword ? 'text' : props.type"
      :placeholder="props.type === 'mail' ? config.mails.exemple : props.placeholder"
      :disabled="props.disabled"
      autocomplete="on"
      @input="updateValue"
    />
    <!-- On précise le type pour éviter qu'il ne submit -->
    <button
      v-if="props.type === 'password'"
      type="button"
      :class="{ active: data.showPassword }"
      @click="data.showPassword = !data.showPassword"
    >
      <fa-icon icon="fa-solid fa-eye" />
    </button>
  </label>
</template>

<script setup>
import { defineProps, reactive, defineEmits } from "vue";

import config from "@/config";

const props = defineProps({
  /**
   * Définit le contenu de l'input ou du textarea.
   */
  modelValue: {
    type: String,
    default: "",
  },
  /**
   * Définit le type de l'input ou si c'est un textarea.
   */
  type: {
    type: String,
    default: "text",
  },
  /**
   * Définit le nom de l'input ou du textarea.
   */
   name: {
    type: String,
    default: undefined,
  },
  /**
   * Définit le label.
   */
  label: {
    type: String,
    default: "",
  },
  /**
   * Définit le placeholder de l'input ou du textarea.
   */
  placeholder: {
    type: String,
    default: "",
  },
  /**
   * Désactive l'input ou le textarea.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const data = reactive({ showPassword: false });

/**
 * Change la valeur du champ.
 * @param {*} e
 */
const updateValue = (e) => {
  emit("update:modelValue", e.target.value);
};
</script>

<style lang="scss">
@use "@/assets/styles/components/_input_form.scss";
</style>
