import { ref } from "vue";

const modalState = ref({});

const openModal = (modalName) => {
  modalState.value[modalName] = true;
};

const closeModal = (modalName) => {
  modalState.value[modalName] = false;
};

export { modalState, openModal, closeModal };
