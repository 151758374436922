import comediens from "./data/comediens";
import staff from "./data/staff";
import pieces from "./data/pieces";

export default {
  api: process.env.VUE_APP_API_URL || `${window.location.protocol}//${window.location.hostname}:8006/api`,
  comediens,
  staff,
  pieces,
  mails: {
    contact: "contact@lestentactrices.fr",
    postmaster: "postmaster@lestentactrices.fr",
    exemple: "exemple@email.fr"
  },
  timeout: 5000,
};
