<template>
  <div class="infinite-loader">
    <span class="loader" />
  </div>
</template>

<script setup></script>

<style lang="scss">
@use "@/assets/styles/components/_infinite_loader.scss";
</style>
