import * as VueRouter from "vue-router";
import { toast } from "vue3-toastify";

import store from"@/store";
import { scrollToElement } from "@/utils";

const router = VueRouter.createRouter({
  scrollBehavior (to) {
    return scrollToElement(to.hash && to.hash != "#pascal" ? to.hash : "body");
  },
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
      meta: {
        title: "Accueil",
        showInMenu: true,
      },
    },
    {
      path: "/troupe",
      name: "troupe",
      component: () => import(/* webpackChunkName: "troupe" */ "../views/TroupeView.vue"),
      meta: {
        title: "La troupe",
        showInMenu: true,
      },
    },
    {
      path: "/troupe/:comedien",
      name: "comedien",
      component: () => import(/* webpackChunkName: "[request]" */ "../views/ComedienView.vue"),
      meta: {
        showInMenu: false,
      },
    },
    {
      path: "/troupe/staff",
      name: "staff",
      component: () => import(/* webpackChunkName: "staff" */ "../views/StaffView.vue"),
      meta: {
        title: "L'équipe technique",
        showInMenu: false,
      },
    },
    {
      path: "/historique",
      name: "history",
      component: () => import(/* webpackChunkName: "history" */ "../views/HistoricView.vue"),
      meta: {
        title: "Historique",
        showInMenu: true,
      },
    },
    {
      path: "/livre-d-or",
      name: "comments_book",
      component: () => import(/* webpackChunkName: "comments_book" */ "../views/CommentsBookView.vue"),
      meta: {
        title: "Livre d'or",
        showInMenu: true,
      },
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
      meta: {
        title: "Contact",
        showInMenu: true,
      },
    },
    // {
    //   path: "/billetterie",
    //   name: "ticketing",
    //   component: () => import(/* webpackChunkName: "ticketing" */ "../views/TicketingView.vue"),
    //   meta: {
    //     title: "Billetterie",
    //     showInMenu: true,
    //   },
    // },
    {
      path: "/profil",
      name: "profile",
      component: () => import(/* webpackChunkName: "profil" */ "../views/ProfileView.vue"),
      meta: {
        title: "Mon profil",
        requiresAuth: true,
      },
    },
    {
      path: "/mentions-legales",
      name: "legal_notice",
      component: () => import(/* webpackChunkName: "legal_notice" */ "../views/LegalNoticeView.vue"),
      meta: {
        title: "Mentions Légales",
      },
    },
    {
      path: "/nouveau-mdp/",
      name: "reset_password",
      component: () => import(/* webpackChunkName: "reset_password" */ "../views/ResetPasswordView.vue"),
      meta: {
        title: "Nouveau mot de passe",
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: () => import(/* webpackChunkName: "404" */ "../views/Page404View.vue"),
      meta: {
        title: "Oups…",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    return store.dispatch("verifyToken")
      .then(() => {
        if (!store.getters.user) {
          // Utilisateur non connecté, redirige vers la page d'accueil
          next({ name: "home" });
        } else {
          // Utilisateur connecté, continue la navigation
          store.commit("setPageTitle", to.meta.title);
          next();
        }
      })
      .catch((err) => {
        toast.error("Une erreur est survenue.");
        console.error("Erreur lors de la vérification de l'authentification", err);
        next();
      });
  } else {
    // La route n'exige pas d'authentification, continue la navigation
    store.commit("setPageTitle", to.name === "home" ? null : to.meta.title);
    next();
  }
});

router.afterEach(() => {
  if (localStorage.getItem("logoutMessage") === "true") {
    toast.success("Déconnexion réussie&nbsp;!");
    localStorage.removeItem("logoutMessage");
  }
});

export default router;
