import config from "@/config";

/**
 * Accède à un élément de la page avec un offset.
 * @param {String} selector Sélecteur CSS.
 */
const scrollToElement = (selector) => {
  if (document.querySelector(selector)) {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        54, // Taille du header
    });
  }
};

/**
 * Vérifie qu'un email est correct.
 * @param {String} email Email tapé à vérifier.
 */
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

/**
 * Affiche un email grâce à JS pour éviter le spams des bots.
 * @param {String} postmaster Si true, l'email est celui du service technique.
 */
const obfuscateEmail = (postmaster = false) => {
  const email = postmaster ? config.mails.postmaster : config.mails.contact;
  const obfuscatedEmail = email.split("").map((char) => `&#${char.charCodeAt(0)};`).join("");
  const mailtoLink = `mailto:${obfuscatedEmail}`;

  return `<a href="${mailtoLink}">${obfuscatedEmail}</a>`;
};

/**
 * Actualise la page;
 */
const refreshPage = () => {
  window.location.reload();
}

export { scrollToElement, isValidEmail, obfuscateEmail, refreshPage };
