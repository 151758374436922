<template>
  <transition name="modal-slide-fade">
    <div
      v-if="showModal"
      class="modal-base"
      :id="props.name"
    >
      <section
        v-click-outside="() => emit('click-outside')"
        class="modal-content"
      >
        <header class="modal-header">
          <div class="topbar">
            <button
              class="close-btn"
              type="button"
              @click="closeModal(props.name)"
            >
              <fa-icon icon="fa-solid fa-xmark" />
            </button>
            <slot name="topbar" />
          </div>
          <div
            v-if="title"
            class="zone-title"
          >
            <h1 class="title">
              {{ title }}
            </h1>
          </div>
        </header>

        <div
          v-if="slots.body"
          class="modal-body"
        >
          <slot name="body" />
        </div>

        <footer
          v-if="slots.footer"
          class="modal-footer"
        >
          <slot name="footer" />
        </footer>
      </section>
    </div>
  </transition>
</template>

<script setup>
import { ref, defineProps, defineEmits, useSlots, watch  } from "vue";

import { modalState, closeModal } from "@/modules/modale";

const props = defineProps({
  /**
   * Nom de la modale.
   */
  name: {
    type: String,
    required: true,
  },
  /**
   * Titre de la modale.
   */
  title: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["click-outside"]);

const slots = useSlots();

const showModal = ref(false);

watch(() => modalState.value[props.name], (newVal) => {
  showModal.value = newVal;
});
</script>

<style lang="scss">
@use "@/assets/styles/components/_modal_base.scss";
</style>
