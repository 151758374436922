export default [
  {
    key: "marina",
    prenom: "Marina",
    nom: "BEYER",
    genre: "F",
    profession: "Justement fonctionnaire… Et pire encore, fonctionnaire retraitée",
    intro: `
      En plus de comédienne, elle est fondatrice, présidente, et metteur en scène de l'association. Que de
      responsabilités&nbsp;!
    `,
    loisirs: [
      {
        nom: "La cuisine",
        description: "Celle anti-rides (avec crème au beurre)",
      },
      {
        nom: "La lecture",
        description: "Sur un transat au soleil",
      },
      {
        nom: "Gym",
        description: "Exercices des doigts de pieds en éventail",
      },
      {
        nom: "La moto",
        description: "Sur le terrible engin-où-elle-ne-reconnait-plus-personne…"
      },
    ],
    aime: "Les fous rires en plein jeu devant le public",
    deteste: "Payer sa troupe (du coup elle ne le fait pas…)",
    prefere: "La salsa et le mojito",
    exploit: " Record du monde de grasse mat'&nbsp;!",
    pieces: [
      {
      nom: "La Salle de Bain",
        role: "Loulou",
      },
      {
        nom: "Les Amazones",
        role: "Martine",
      },
      {
        nom: "Elle voit des nains partout",
        role: "Le Roi, la Mort et Carabosse",
      },
      {
        nom: "Toc Toc",
        role: "Sandy",
      },
      {
        nom: "Si c'était à refaire",
        role: "Le Dr. JOUVENCE",
      },
      {
        nom: "Le Gai Mariage",
        role: "Dodo",
      },
      {
        nom: "Un Beau Salaud",
        role: "Catherine",
      },
    ],
  },
  {
    key: "lorraine",
    prenom: "Lorraine",
    nom: "PULBY",
    genre: "F",
    profession: "Déléguée médicale",
    intro: `
      En plus de comédienne, elle est trésorière, chargée des achats, du bilan et de l'organisation des réunions de
      l'association.
    `,
    loisirs: [
      {
        nom: "La cuisine",
        description: " Créatrice d'une nouvelle recette “les pieds dans l'plat” et faire de la quiche de son pays…",
      },
      {
        nom: "La course",
        description: "À pied, à vélo, en voiture, à vélo sur le toit de la voiture",
      },
      {
        nom: "Le shopping",
        description: "Être à côté de ses nouvelles pompes",
      },
    ],
    aime: "Échanger autour d'un verre après les représentations",
    deteste: "Oublier un accessoire avant de jouer",
    prefere: "Le rock'n'roll et une p'tite mousse",
    exploit: "Triple axel au patinage artistique dans son jardin&nbsp;!",
    pieces: [
      {
        nom: "La Salle de Bain",
        role: "Marie",
      },
      {
        nom: "Les Amazones",
        role: "Loïc",
      },
      {
        nom: "Elle voit des nains partout",
        role: "Blanche-Neige et Amelys",
      },
      {
        nom: "Toc Toc",
        role: "Fred",
      },
      {
        nom: "Si c'était à refaire",
        role: "Mlle FRANTINI",
      },
      {
        nom: "Le Gai Mariage",
        role: "Brigitte",
      },
      {
        nom: "Un Beau Salaud",
        role: "Adeline",
      },
    ],
  },
  {
    key: "evelyne",
    prenom: "Evelyne",
    nom: "BEYER",
    genre: "F",
    profession: "Déléguée médicale",
    intro: `
      En plus de comédienne, elle est secrétaire, chargée de la publicité, de la communication et de la relation
      publique de l'association.
    `,
    loisirs: [
      {
        nom: "La cuisine",
        description: "Quand elle est belle et grande avec un homme qui fait à manger",
      },
      {
        nom: "Les travaux manuels",
        description: "Chauffage… de sa carte bancaire",
      },
      {
        nom: "Faire la fête",
        description: "Reine de la nuit sous les Sunlights des Trooopiiiiiques&nbsp;!",
      },
      {
        nom: "La formule 1",
        description: `
          Dans sa voiture et tout en se maquillant, se parfumant, téléphonant, grignotant, râlant, klaxonnant,
          chantant…
        `,
      },
    ],
    aime: "Frapper les trois coups avant que la lumière ne s'allume sur la scène",
    deteste: "La pression avant de monter sur scène",
    prefere: "L'Italie et le champagne",
    exploit: "Escalade du Mont Blanc parfum chocolat&nbsp;!",
    pieces: [
      {
        nom: "La Salle de Bain",
        role: "Ange",
      },
      {
        nom: "Les Amazones",
        role: "Micky",
      },
      {
        nom: "Elle voit des nains partout",
        role: "Le Connétable et le Roi d'Angleterre",
      },
      {
        nom: "Toc Toc",
        role: "Lili",
      },
      {
        nom: "Si c'était à refaire",
        role: "Claudine",
      },
      {
        nom: "Le Gai Mariage",
        role: "Sophie",
      },
      {
        nom: "Un Beau Salaud",
        role: "Barbara",
      },
    ],
  },
  {
    key: "maria",
    prenom: "Maria",
    nom: "BEYER",
    genre: "F",
    profession: "Joyeuse retraitée",
    intro: `
      En plus de comédienne, elle est secrétaire adjointe, chargée de la billetterie et de la publicité de
      l'association.
    `,
    loisirs: [
      {
        nom: "La cuisine",
        description: "Chef de rang au Campanile, 3 étoiles Michelin",
      },
      {
        nom: "Les travaux manuels",
        description: "Électricité, on l'a branchée sur du 100 000 V…",
      },
      {
        nom: "Les langues",
        description: "Alsacien lu, écrit et parlé, parlé, parlé… et reparlé",
      },
      {
        nom: "La marche",
        description: "Ultrarapide sans fermer la bouche et en apnée",
      },
    ],
    aime: "Discuter avec toute la salle avant de jouer",
    deteste: "Quand les autres improvisent les répliques avant les siennes",
    prefere: "Le cha-cha-cha et le Picon bière",
    exploit: `
      Elle connaît tout le monde et tout le monde la connaît. Si vous ne la connaissez pas, soyez sûr qu'elle, elle
      vous connaît&nbsp;!
    `,
    pieces: [
      {
        nom: "La Salle de Bain",
        role: "Moumoune",
      },
      {
        nom: "Les Amazones",
        role: "Annie",
      },
      {
        nom: "Elle voit des nains partout",
        role: "Le Cuisinier Alsacien, le Petit Poucet et le Prince Charmant",
      },
      {
        nom: "Toc Toc",
        role: "Marie",
      },
      {
        nom: "Si c'était à refaire",
        role: "Mme CARNOT",
      },
      {
        nom: "Le Gai Mariage",
        role: "Edmonde",
      },
      {
        nom: "Un Beau Salaud",
        role: "Mado",
      },
    ],
  },
];
