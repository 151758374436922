import { createStore } from "vuex";
import { toast } from "vue3-toastify";

import Api from "@/modules/axios";

export default createStore({
  state: {
    pageTitle: "",
    user: null,
    token: localStorage.getItem("token") || "",
  },
  getters: {
    pageTitle(state) {
      return state.pageTitle;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
  },
  mutations: {
    setPageTitle(state, title) {
      state.pageTitle = title;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      token ? localStorage.setItem("token", token) : localStorage.removeItem("token");
    },
  },
  actions: {
    login(store, credentials) {
      return Api({ noHeader: true })
        .post("/login/", credentials)
        .then((res) => {
          store.commit("setUser", res.data.user);
          store.commit("setToken", res.data.token);
          return res.data.user?.id;
        });
    },
    verifyToken(store) {
      if (store.getters.token) {

        return Api().post("/verify_token/", { token: store.getters.token })
        .then((res) => {
          store.commit("setUser", res.data.user);
        })
        .catch(() => {
          store.commit("setUser", null);
          store.commit("setToken", null);
        });
      }
    },
    logout(store) {
      Api().post("/logout/", {})
      .then(() => {
          store.commit("setUser", null);
          store.commit("setToken", null);
          localStorage.setItem("logoutMessage", "true");
          location.reload();
        })
        .catch((err) => {
          toast.error(err.response?.data?.error  || "Une erreur est survenue.");
        });
    },
  },
});
