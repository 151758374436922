<template>
  <ModalBase
    class="modal-auth"
    name="login"
    title="Connexion"
    @click-outside="() => closeAndClear()"
  >
    <template #topbar>
      <button
        class="bold"
        type="button"
        @click="openRegisterModal"
      >
        <fa-icon icon="fa-solid fa-arrow-right" />
        Inscription
      </button>
    </template>

    <template #body>
      <form @submit.prevent="login">
        <InputForm
          v-model="loginInfos.username"
          type="mail"
          label="Votre adresse mail"
        />
        <InputForm
          v-model="loginInfos.password"
          type="password"
          label="Votre mot de passe"
          placeholder="Chivita_Chivita_HaHaHa"
        />
        <p class="error-message">
          {{ errorMessage }}
        </p>
        <div class="zone-btn">
          <button
            class="btn"
            type="submit"
          >
            Se connecter
            <fa-icon icon="fa-solid fa-arrow-right-to-bracket" />
          </button>
          <button
            class="underline"
            type="button"
            @click.prevent="openForgotPasswordModal"
          >
            Mot de passe oublié
          </button>
        </div>
      </form>
    </template>
  </ModalBase>
</template>

<script setup>
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

import { openModal, closeModal } from "@/modules/modale";

import InputForm from "@/components/InputForm";
import ModalBase from "@/components/modales/ModalBase.vue";

const store = useStore();

let errorMessage = ref("");

let loginInfos = reactive({
  username: "",
  password: "",
});

/**
 * Ouvre la modale d'inscription.
 */
const openRegisterModal = () => {
  closeAndClear();
  openModal("register");
};

/**
 * Affiche la modale de demande de changement de mot de passe.
 */
const openForgotPasswordModal = () => {
  closeAndClear();
  openModal("forgot_password");
};

/**
 * Ferme la modale et vide tous les champs.
 */
const closeAndClear = () => {
  closeModal("login");

  errorMessage.value = "";
  loginInfos.username = "";
  loginInfos.password = "";
};

/**
 * Connecte l'utilisateur.
 */
const login = () => {
  store.dispatch("login", loginInfos)
    .then(() => {
      closeAndClear();
      toast.success("Connexion réussie&nbsp;!");
    })
    .catch((err) => {
      errorMessage.value = err.response?.data?.error  || "Une erreur est survenue.";
    });
};
</script>

<style lang="scss">
@use "@/assets/styles/components/_modal_auth.scss";
</style>
